import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Accordion} from "react-bootstrap";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Loader from "../components/Loader";
import Link from "gatsby-link";
import Tekrevolcareer from "../components/tekrevolcareer";

function Careerlisting(props) {
    const [modalShow, setModalShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/career-listing?status=1`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <>
            {isLoaded ?
                <Loader/>
                :
                <div className="tekrevol_gray_bg listing_page p-100">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="example" data-text="Apply Now">
                                    <h2>Come join us!</h2>
                                </div>
                                <div className="job_count">Current Openings
                                    - {userData.length < 10 ? `0${userData.length}` : `${userData.length}`} </div>
                                <Accordion defaultActiveKey="0">
                                    {userData?.map((value, i) => (
                                        <>
                                            <Accordion.Item eventKey={i} key={i}>
                                                <Accordion.Header>{value.job_title}
                                                    <span>{value.job_location}</span></Accordion.Header>
                                                <Accordion.Body>
                                                    <div dangerouslySetInnerHTML={{__html: value.job_description}}/>
                                                    <div className="btn_talk" onClick={() => setModalShow(true)}>
                                                        <Link to="#" className="border_btn">Apply Now</Link>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Tekrevolcareer show={modalShow} onHide={() => setModalShow(false)}
                                                            title={value.job_title}/>
                                        </>
                                    ))}
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>

                </div>
            }
        </>
    );
}

export default Careerlisting;