import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Loader from "../components/Loader";
import Seo from "../layout/seo";
import {checkData} from "../../helper";
import Breadcrumbs from "../components/breadcrumbs";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Banner from "./banner";
import Careerlisting from "./careerlisting";


function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/career-page`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);


    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Careers"/>
                    <Banner data={userData}/>
                    <Careerlisting/>

                </>
            }
        </Layout>
    );
}

export default Index;